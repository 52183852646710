import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupModalFooter } from '../models/popup/popup-modal-footer';
import { PopupModalBody } from '../models/popup/popup-modal-body';
import { PopupModalButtonType } from '../models/popup/popup-modal-button-type';

@Injectable({
  providedIn: 'root'
})
export class PopupModalService {
  modalTitle: Subject<string> = new Subject<string>();
  modalBody: Subject<PopupModalBody> = new Subject<PopupModalBody>();
  modalBodyUserData: Subject<PopupModalBody> = new Subject<PopupModalBody>();
  modalFooter: Subject<PopupModalFooter> = new Subject<PopupModalFooter>();
  modalStatus: Subject<boolean> = new Subject<boolean>();
  modalNotificationButtonClicked: Subject<PopupModalButtonType> = new Subject<PopupModalButtonType>();

  constructor() {
  }

  changeModalVisibility( status: boolean ) {
    this.modalStatus.next(status);
  }

  setModalTitle( title: string ) {
    this.modalTitle.next(title);
  }

  setModalBody( body: PopupModalBody ) {
    this.modalBody.next(body);
  }

  setModalFooter( footer: PopupModalFooter ) {
    this.modalFooter.next(footer);
  }

  setNotificationButtonClicked( buttonType: PopupModalButtonType ) {
    this.modalNotificationButtonClicked.next(buttonType);
  }

  setModalBodyUserData(body: PopupModalBody){
    this.modalBodyUserData.next(body);
  }
}
