import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopupModalService } from '../../../services/popupmodal.service';
import { PopupModalBody } from '../../../models/popup/popup-modal-body';
import { PopupModalFooter } from '../../../models/popup/popup-modal-footer';
import { PopupModalButtonType } from '../../../models/popup/popup-modal-button-type';
import { NotificationMessageType } from '../../../models/notification-message-type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notificationmodal',
  templateUrl: './notificationmodal.component.html',
  styleUrls: [ './notificationmodal.component.scss' ]
})
export class NotificationModalComponent implements OnInit, OnDestroy {
  @ViewChild('showNotificationModal') showNotificationModal: ElementRef;
  @ViewChild('notificationBody') notificationBody: ElementRef;
  private subscriptionModalStatus: Subscription;
  private subscriptionModalTitle: Subscription;
  private subscriptionModalBody: Subscription;
  private intervalId;
  private intervalBodyId;

  modalTitle: string;
  modalBody: PopupModalBody;
  modalFooter: PopupModalFooter;

  constructor( private popupModalService: PopupModalService ) {
    this.modalBody = new PopupModalBody();

    this.subscriptionModalStatus = this.popupModalService.modalStatus.subscribe(status => this.changePopupModalStatus(status));
    this.subscriptionModalTitle = this.popupModalService.modalTitle.subscribe(title => this.setModalTitle(title));
    this.subscriptionModalBody = this.popupModalService.modalBody.subscribe(modalBody => this.popupModalBody(modalBody));

    this.popupModalService.modalFooter.subscribe(modalFooter => this.popupModalFooter(modalFooter));
  }

  ngOnDestroy() {
    this.subscriptionModalStatus.unsubscribe();
    this.subscriptionModalTitle.unsubscribe();
    this.subscriptionModalBody.unsubscribe();
  }

  ngOnInit(): void {
  }

  setModalTitle( title: string ) {
    this.modalTitle = title;
  }

  changePopupModalStatus( status: boolean ) {
    this.intervalId = setInterval(() => {
      this.showNotificationModal.nativeElement.click();
      clearInterval(this.intervalId);
    }, 400);
  }

  popupModalBody( popupModalBody: PopupModalBody ) {
    this.modalBody = popupModalBody;

    this.generateNotificationElement(popupModalBody);
  }

  popupModalFooter( popupModalFooter: PopupModalFooter ) {
    this.modalFooter = popupModalFooter;
  }

  closeModalDialog() {
    this.removeNotificationElements();
    this.showNotificationModal.nativeElement.click();
  }

  notificationSend() {
    this.popupModalService.setNotificationButtonClicked(PopupModalButtonType.SEND);
    this.closeModalDialog();
  }

  generateNotificationElement( popupModalBody: PopupModalBody ) {
    const htmlElement: HTMLElement = document.getElementById('notificationBody');
    const divElement: HTMLElement = document.createElement('div');

    divElement.setAttribute('id', 'notificationElements');

    popupModalBody.notificationMessage.forEach(data => {

      switch ( data.type ) {
        case NotificationMessageType.NORMAL: {

          let spanElement: HTMLElement = document.createElement('span');
          spanElement.innerText = data.message;
          divElement.appendChild(spanElement);

          break;
        }
        case NotificationMessageType.BOLD: {

          let boldElement: HTMLElement = document.createElement('b');
          boldElement.innerText = data.message;
          divElement.appendChild(boldElement);

          break;
        }
        case NotificationMessageType.UNARTICULATED: {

          let unarticulatedElement: HTMLElement = document.createElement('u');
          unarticulatedElement.innerText = data.message;
          divElement.appendChild(unarticulatedElement);

          break;
        }
      }

      if ( data.breakLine ) {
        let breakElement: HTMLElement = document.createElement('br');
        divElement.appendChild(breakElement);
      }
    });

    htmlElement.appendChild(divElement);
  }

  removeNotificationElements() {
    const htmlElement: HTMLElement = document.getElementById('notificationBody');

    while ( htmlElement.hasChildNodes() ) {
      htmlElement.removeChild(htmlElement.firstChild);
    }
  }
}
