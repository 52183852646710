import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { NotificationMessage } from '../notification-message';
import { PopupModalBody } from '../popup/popup-modal-body';

export class PopupModalBodyBuilder {
  private popupModalBody: PopupModalBody;

  constructor() {
    this.popupModalBody = new PopupModalBody();
  };

  public userMessage( userMessage: string ): PopupModalBodyBuilder {
    this.popupModalBody.userMessage = userMessage;
    return this;
  }

  public notificationMessage( notificationMessage: NotificationMessage[] ): PopupModalBodyBuilder {
    this.popupModalBody.notificationMessage = notificationMessage;
    return this;
  }

  public build(): PopupModalBody {
    if ( isNotNullOrUndefined(this.popupModalBody) ) {
      return this.popupModalBody;
    }
  }
}
