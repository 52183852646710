import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { LocaleService, TranslationService } from 'angular-l10n';
import { Table } from 'primeng';
import { ActivatedRoute } from '@angular/router';
import { DetailedviewService } from '../../services/detailedview.service';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Translator } from '../../utils/Translator';
import CssUtils from '../../utils/CssUtils';
import { ContractHistoryControlService } from 'src/app/services/control-layer/ContractHistoryControlService';
import { ContractHistoryLogicService } from 'src/app/services/logic-layer/ContractHistoryLogicService';
import { ContractFilter } from 'src/app/models/contracthistory/ContractFilter';
import {ContractHistoryMode} from '../../models/contracthistory/contract-history-mode';

@Component({
  selector: 'app-contract-history',
  templateUrl: './contract-history.component.html',
  styleUrls: ['./contract-history.component.scss']
})
export class ContractHistoryComponent implements OnInit, AfterViewInit{
  @ViewChild('contracthistory') contractHistory: Table;

  mode = ContractHistoryMode;

  private translator: Translator = new Translator(this.translateService);

  constructor( public control: ContractHistoryControlService,
               public localeService: LocaleService,
               public logic: ContractHistoryLogicService,
               private translateService: TranslationService,
               private detailedViewService: DetailedviewService,
               private route: ActivatedRoute) {
    this.logic.loading = true;
  }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.logic.mode = params['role'] === 'managers' ? ContractHistoryMode.MANAGEMENT : ContractHistoryMode.HISTORY;
      this.setup();
      this.control.load();
    });

    CssUtils.setDropdownCss();
    this.detailedViewService.resetDetailedView();
  }

  ngAfterViewInit() {
    this.control.sort(this.contractHistory, 'desc', 'startDate');
    this.translateService.translationChanged().subscribe(res => {
      this.setup();
    });
  }

  setup() {
    this.logic.locale = this.localeService.getCurrentLanguage();
    this.control.initFilter();
    this.control.displayedColumns = this.translator.getContractListTableColumns(this.logic.mode);
    this.control.updateDisplayedColumns();
  }

  getPlaceholder( columnName: string ): string {
    const result = this.translator.getContractListTableColumns(this.logic.mode).find(p => p.field === columnName);
    if ( isNotNullOrUndefined(result) ) {
      return result.header;
    }
    return '';
  }

  resetFilter() {
    this.logic.searchDisabled = true;
    this.control.filter = new ContractFilter();
    this.control.applyFilter();
  }
}
