export class UserData {
  userName = '';
  userEmail = '';
  firstName = '';
  lastName = '';
  personalNr = '';

  constructor() {
  }
}
