import { Injectable } from '@angular/core';
import { DefaultService } from './default.service';
import { Contract } from '../models/contracthistory/contract.model';
import { environment } from '../../main';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ContractService extends DefaultService {
  getAllContracts(): Observable<Contract[]> {
    return this.run(DefaultService.GET, environment.contract.contracts);
  }

  getMyContract(): Observable<Contract[]>{
    return this.run(DefaultService.GET, environment.contract.my_contracts);
  }

  modifyContract(id: string, contract: Contract): Observable<Contract>{
    return this.run(DefaultService.PUT, environment.contract.contracts + '/' + id, contract);
  }
}
