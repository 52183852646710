import { Injectable } from '@angular/core';
import {ContractHistoryMode} from "../../models/contracthistory/contract-history-mode";

@Injectable({
  providedIn: 'root'
})
export class ContractHistoryLogicService {
  public locale;
  public loading;
  public mode: ContractHistoryMode;
  public searchDisabled: boolean;

  constructor() {
  }
}
