import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {LocalStorageService} from './localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultService {
  public static PUT = 'PUT';
  public static GET = 'GET';
  public static POST = 'POST';
  public static DELETE = 'DELETE';

  constructor(private http: HttpClient,
              public localStorageService: LocalStorageService) {}

  run<T>( method: string, path: string, data?, inOptions? ) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.localStorageService.getItem('id_token')
    });
    let options = { headers: headers };

    if ( inOptions ) {
      options = inOptions;
    }

    switch ( method ) {
      default:
        return this.http.get<T>(
          path,
          options
        );

      case DefaultService
        .POST:
        return this.http.post<T>(
          path,
          data,
          options
        );
      case DefaultService
        .DELETE:
        return this.http.delete<T>(
          path,
          options
        );
      case DefaultService
        .PUT:
        return this.http.put<T>(
          path,
          data,
          options
        );
    }
  }
}
