import { Injectable } from "@angular/core";
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ContractBuilder } from "src/app/models/builders/contract-builder.model";
import { Contract } from "src/app/models/contracthistory/contract.model";
import { ContractApi } from "../api-layer/ContractApi";

@Injectable({
    providedIn: 'root'
})
export class MyContractControlService {
    public myContract: Contract[];

    constructor(private contractApi: ContractApi) { }

    load() {
        this.contractApi.getMyContract(this.loadContract.bind(this));
    }

    loadContract(data: Contract[]) {
        data.forEach(contract => {
            if (isNotNullOrUndefined(contract.userData.firstName)
                && isNotNullOrUndefined(contract.userData.lastName)) {
                this.myContract = [];
                this.myContract.push(new ContractBuilder()
                    .personalNr(contract.personalNr)
                    .startDate(contract.startDate)
                    .employmentStartDate(contract.employmentStartDate)
                    .holidayEntitlement(contract.holidayEntitlement)
                    .build()
                );
            }
        });
    }
}