export class MyContractTableColumns{
    
    header: string;
    field: string;
    display: string = 'table-cell';
    cssClass: string;
   

    constructor( field: string, cssClass: string, header: string) {
      this.header = header;
      this.field = field;
      this.cssClass = cssClass;
    }

}