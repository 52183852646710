import { UserData } from "../user-data.model";
import {JobTitle} from "./job-title";
import {ContractType} from "./contract-type";
import { isNotNullOrUndefined } from "codelyzer/util/isNotNullOrUndefined";

export class Contract {
  id: string;
  startDate: Date;
  endDate: Date;
  employmentStartDate: Date;
  holidayEntitlement: number;
  usedHolidays: number;
  firstName: string;
  lastName: string;
  personalNr: string;
  userData: UserData;

  constructor() {
  }

  public extractUserData() {
    if ( isNotNullOrUndefined(this.userData) ) {
      this.firstName = this.userData.firstName;
      this.lastName = this.userData.lastName;
      this.personalNr = this.userData.personalNr;
    }
  }
}
