import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { L10nConfig, L10nLoader, LocalizationModule, ProviderType, StorageStrategy } from 'angular-l10n';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';

import {
  ButtonModule,
  CalendarModule,
  DropdownModule,
  EditorModule,
  MessageService,
  TableModule,
  ToastModule
} from 'primeng';
import { A2Edatetimepicker } from 'ng2-eonasdan-datetimepicker';

import { ErrorInterceptor } from './common/error.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { FilterPipe } from './pipes/filter.pipe';
import { InputModalComponent } from './components/popupmodals/inputmodal/inputmodal.component';
import { NotificationModalComponent } from './components/popupmodals/notificationmodal/notificationmodal.component';
import { environment } from '../main';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {LocalStorageService} from './services/localStorage.service';
import { ContractHistoryComponent } from './components/contract-history/contract-history.component';
import { MyContractComponent } from './components/my-contract/my-contract.component';
import { NavigationModule } from 'ams-navbar';

const l10nConfig: L10nConfig = {
  locale: {
    languages: [
      { code: 'de', dir: 'ltr' },
      { code: 'en', dir: 'ltr' },
      { code: 'fr', dir: 'ltr' },
      { code: 'fa', dir: 'rtl' }
    ],
    defaultLocale: { languageCode: 'de', countryCode: 'DE' },
    currency: 'EUR',
    storage: StorageStrategy.Local
  },
  translation: {
    providers: [
      {
        type: ProviderType.Static, prefix: './assets/localization/locale-'

      }
    ],
    caching: true,
    missingValue: 'No key',
    composedKeySeparator: '.'
  }
};

// translate for date pipe
registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    InputModalComponent,
    NotificationModalComponent,
    ContractHistoryComponent,
    MyContractComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatTooltipModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    ToastModule,
    FormsModule,
    TableModule,
    CalendarModule,
    EditorModule,
    ButtonModule,
    DropdownModule,
    A2Edatetimepicker,
    NgbModule,
    NgbAlertModule,
    HttpClientModule,
    FlexLayoutModule,
    LocalizationModule.forRoot(l10nConfig),
    NavigationModule
  ],
  exports: [ FilterPipe ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: APP_INITIALIZER,
      useFactory: ( keycloak: KeycloakService ) => {
        return () => {
          return new Promise<void>(async ( resolve, reject ) => {
            try {
              await keycloak.init({
                config: environment.keycloak.config,
                initOptions: {
                  onLoad: 'login-required',
                  checkLoginIframe: false,
                  redirectUri: document.location.href
                }
              });
              resolve();

              const localStorageService: LocalStorageService = new LocalStorageService();

              if (keycloak.isLoggedIn()) {
                keycloak.loadUserProfile().then(profile => {
                  localStorageService.setObjectItem('userProfile', profile);
                  localStorageService.setObjectItem('userRoles', keycloak.getUserRoles());
                }).catch(e => console.error('Error on loading profile: ' + e));
                keycloak.getToken(false).then(token => {
                  localStorageService.setObjectItem('id_token', token);
                }).catch(e => console.error('Error on getting SSO token: ' + e));
              }
            } catch ( e ) {
              reject(e);
            }
          });
        };
      },
      multi: true,
      deps: [ KeycloakService ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    AuthGuardService,
    MessageService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor( public l10nLoader: L10nLoader ) {
    this.l10nLoader.load().then(() => console.log('Loaded translations'));
  }
}
