import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { RoleType } from './models/role-type.model';
import {MyContractComponent} from "./components/my-contract/my-contract.component";
import {ContractHistoryComponent} from "./components/contract-history/contract-history.component";

const externalUrlProvider = new InjectionToken('externalUrlProvider');

const routes: Routes = [
  {
    path: 'mycontract',
    canActivate: [ AuthGuardService ],
    component: MyContractComponent,
    data: { roles: [
      RoleType.HR_MANAGERS,
      RoleType.TEAM_LEADS,
      RoleType.EMPLOYEES,
      RoleType.CONTRACTS,
      RoleType.ME_CONTRACTS,
      RoleType.FILES,
      RoleType.ME_FILES ] }
  },
  {
    path: 'contracthistory/:role',
    canActivate: [ AuthGuardService ],
    component: ContractHistoryComponent,
    data: { roles: [ 
      RoleType.HR_MANAGERS,
      RoleType.TEAM_LEADS,
      RoleType.EMPLOYEES,
      RoleType.CONTRACTS,
      RoleType.ME_CONTRACTS,
      RoleType.FILES,
      RoleType.ME_FILES ] }
  },
  {
    path: 'externalRedirect',
    canActivate: [ AuthGuardService ],
    resolve: { url: externalUrlProvider },
    component: MyContractComponent,
    data: { roles: [ 
      RoleType.HR_MANAGERS,
      RoleType.TEAM_LEADS,
      RoleType.EMPLOYEES,
      RoleType.CONTRACTS,
      RoleType.ME_CONTRACTS,
      RoleType.FILES,
      RoleType.ME_FILES ] }
  },
  {
    path: '',
    redirectTo: '/mycontract',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/mycontract',
    pathMatch: 'full'
  }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: ( route: ActivatedRouteSnapshot ) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_self');
      }
    }
  ],
  imports: [ RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' }) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {
}
