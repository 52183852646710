import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from 'primeng/api'
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor( private messageService: MessageService ) {
  }

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError(( error: HttpErrorResponse ) => {
        let errorMessage = '';
        if ( error.error instanceof ErrorEvent ) {
          // client-side error
          errorMessage = 'Error: ${error.error.message}';
        } else {
          // server-side error
          console.log('Error interceptor', error);
          if ( error && error.error ) {
            errorMessage = error.error.message;
          }
        }
        // show error message
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: errorMessage
        });

        return throwError(errorMessage);
      })
    );
  }
}
