import { Component, OnInit } from '@angular/core';
import { environment } from '../main';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})
export class AppComponent implements OnInit {
  title = 'ams-contract-frontend';

  config = {
    'ams-contract-frontend': {
      loaded: false,
      path: 'ams-contract-frontend/main.js',
      element: 'contract-frontend'
    }
  };

  ngOnInit() {
  }


  load( name: string ): void {

    const configItem = this.config[name];
    if ( configItem.loaded ) {
      return;
    }

    const content = document.getElementById('content');

    const script = document.createElement('script');
    script.src = configItem.path;
    content.appendChild(script);

    const element: HTMLElement = document.createElement(configItem.element);
    content.appendChild(element);

    script.onerror = () => console.error(`error loading ${configItem.path}`);

  }
}
