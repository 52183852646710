import { Injectable } from '@angular/core';
import { ContractService } from '../contract.service';
import { ContractHistoryLogicService } from '../logic-layer/ContractHistoryLogicService';
import { MessagingApi } from './MessagingApi';
import { Contract } from 'src/app/models/contracthistory/contract.model';

@Injectable({
  providedIn: 'root'
})
export class ContractApi {

  constructor( private contractService: ContractService,
               public logic: ContractHistoryLogicService,
               private messagingApi: MessagingApi ) {
  }

  getAllContracts(callBack: Function){
    return this.contractService.getAllContracts().subscribe(
      ( data: Contract[] ) => callBack(data),
      ( error ) => this.messagingApi.showGeneralError()
    );
  }

  getMyContract(callBack: Function){
    return this.contractService.getMyContract().subscribe(
      ( data: Contract[] ) => callBack(data),
      ( error ) => this.messagingApi.showGeneralError()
    );
  }

  modifyContract(id: string, contract: Contract) {
    return this.contractService.modifyContract(id, contract).subscribe(
      data => { return data; },
      (error) => this.messagingApi.showGeneralError()
    );
  }
}
