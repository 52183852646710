import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Contract } from '../contracthistory/contract.model';
import { UserData } from '../user-data.model';
import {JobTitle} from "../contracthistory/job-title";
import {ContractType} from "../contracthistory/contract-type";

export class ContractBuilder {
  private contract: Contract;

  constructor() {
    this.contract = new Contract();
  }

  public id( id: string ): ContractBuilder {
    this.contract.id = id;
    return this;
  }

  public startDate(startDate: Date): ContractBuilder {
    this.contract.startDate = startDate;
    return this;
  }

  public endDate(endDate: Date): ContractBuilder {
    this.contract.endDate = endDate;
    return this;
  }

  public employmentStartDate(employmentStartDate: Date): ContractBuilder {
    this.contract.employmentStartDate = employmentStartDate;
    return this;
  }

  public holidayEntitlement(holidayEntitlement: number): ContractBuilder {
    this.contract.holidayEntitlement = holidayEntitlement;
    return this;
  }

  public usedHolidays(usedHolidays: number): ContractBuilder {
    this.contract.usedHolidays = usedHolidays;
    return this;
  }

  public userData( userData: UserData ): ContractBuilder {
    this.contract.userData = userData;
    return this;
  }
  public firstName( firstName: string ): ContractBuilder {
    this.contract.firstName = firstName;
    return this;
  }
  public lastName( lastName: string ): ContractBuilder {
    this.contract.lastName = lastName;
    return this;
  }
  public personalNr( personalNr: string ): ContractBuilder {
    this.contract.personalNr = personalNr;
    return this;
  }

  public build(): Contract {
    if ( isNotNullOrUndefined(this.contract) ) {
      this.contract.extractUserData();
      return this.contract;
    }
  }
}
