import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor( private keycloak: KeycloakService, private router: Router ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
    if ( !this.keycloak.isLoggedIn() ) {
      this.keycloak.login();
    }

    var requiredRoles = route.data.roles;
    if ( requiredRoles && this.hasRequiredRoles(requiredRoles) ) {
      return true;
    }
    return false;
  }

  private hasRequiredRoles( required: string[] ): boolean {
    var accessGranted = false;
    this.keycloak.getUserRoles().forEach(userRole => {
      if ( required.indexOf(userRole) > -1 ) {
        return accessGranted = true;
      }
    });
    return accessGranted;
  }
}
