import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {

  transform( value: any[], filterString: string, propName: string, propName2?: string ): any[] {
    if ( !value || !filterString ) {
      return value;
    }
    const resultArray = [];
    for ( const item of value ) {
      if ( item[propName].toLowerCase().startsWith(filterString.toLowerCase())
        || ( propName2 && item[propName2] && item[propName2].toLowerCase().startsWith(filterString.toLowerCase()) ) ) {
        resultArray.push(item);
      }
    }
    return resultArray;
  }


}
