import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { DatePipe } from '@angular/common';

export class TextValidators {

  constructor() {
  }

  isContains( text: string, searchText: string ): boolean {
    if ( this.isEmpty(text) || this.isEmpty(searchText) ) {
      return true;
    }
    return text.toLowerCase().indexOf(searchText.toLowerCase().trim()) > -1;
  }

  isEmpty( text: string ) {
    if ( isNotNullOrUndefined(text) ) {
      return ( text.trim().length == 0 );
    }
    return true;
  }

  validatorOnlyPositiveInteger( event: KeyboardEvent ): boolean {
    return this.isNumber(event.key);
  }

  validatorOnlyPositiveDouble( event: KeyboardEvent ): boolean {
    if ( event.key == ',' || event.key == '.' ) {
      return true;
    }
    return this.isNumber(event.key);
  }

  validatorSignedDouble( event: KeyboardEvent, text: string ): boolean {
    if ( event.key == ',' && !this.isEmpty(text) ) {
      return text.indexOf(',') == -1;
    }
    if ( event.key == '-' && this.isEmpty(text) ) {
      return true;
    }
    return this.isNumber(event.key);
  }

  private isNumber( key: string ): boolean {
    return /[0-9]/.exec(key) != null;
  }

  isEuropeLetter( event: KeyboardEvent ): boolean {
    return /[a-zA-ZäöüÄÖÜßéèêáàâíìîóòôúùû]/.exec(event.key) != null;
  }

  // Currently, we allow "aug" and "sept" as month for other languages
  validatorDateKeyPressed( event: KeyboardEvent ) {
    return /[0-9a-zA-Z äöüÄÖÜßéèêáàâíìîóòôúùû\.\/]/.exec(event.key) != null;
  }

  containsDate( date: Date, searchText: string, locale: string ) {
    let datePipe = new DatePipe(locale);
    if ( isNotNullOrUndefined(date) && !this.isEmpty(searchText) ) {
      return this.isContains(datePipe.transform(date, 'mediumDate', locale), searchText);
    }
    return true;
  }

  containsDateOrTime( date: Date, searchText: string, locale: string ) {
    let datePipe = new DatePipe(locale);
    if ( isNotNullOrUndefined(date) && !this.isEmpty(searchText) ) {
      return this.isContains(datePipe.transform(date, 'mediumDate', locale), searchText)
        || this.isContains(datePipe.transform(date, 'shortTime', locale), searchText);
    }
    return true;
  }

  isEqualByValue( num1: number, num2: string ) {
    if ( isNotNullOrUndefined(num1) && !this.isEmpty(num2) ) {
      return num1 === parseInt(num2);
    }
    return true;
  }

  isEqualByString( num1: number, num2: string ) {
    if ( isNotNullOrUndefined(num1) && isNotNullOrUndefined(num2) && num2 != '' ) {
      let num1Str = num1.toString().trim();
      num2 = num2.trim();
      return num1Str.startsWith(num2);
    }
    return true;
  }
}
