import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environmentLoader as environmentLoaderPromise } from "./assets/environments/environmentLoader";
import * as placeholder from './assets/environments/defaultEnvironment.json';

export var environment = placeholder;

environmentLoaderPromise.then(env => {
  if (env.production) {
    enableProdMode();
  }

  environment = env;

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
