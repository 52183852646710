import { MessageService } from 'primeng';
import { Injectable } from '@angular/core';
import { TranslationService } from 'angular-l10n';

@Injectable({
  providedIn: 'root'
})
export class MessagingApi {

  constructor( private messageService: MessageService,
               private translateService: TranslationService ) {
  }

  showSimpleError() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Error ',
      detail: 'Error'
    });
  }

  showError( message: string ) {
    this.messageService.add({
      severity: 'warn',
      summary: 'Error ',
      detail: message
    });
  }

  showSimpleWarning() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Info ',
      detail: 'Warning'
    });
  }

  showWarning( message: string ) {
    this.messageService.add({
      severity: 'warn',
      summary: 'Info ',
      detail: message
    });
  }

  showSimpleSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success ',
      detail: 'Successful'
    });
  }

  showSuccess( message: string ) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success ',
      detail: message
    });
  }

  showExportSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success ',
      detail: this.translateService.translate('message.exportSucess')
    });
  }

  showExportError() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Error ',
      detail: this.translateService.translate('message.exportFail')
    });
  }

  showGeneralError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error ',
      detail: this.translateService.translate('message.error')
    });
  }

  showSendEmailSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success ',
      detail: this.translateService.translate('message.sucessEmail ')
    });
  }

  showSuccessLoading() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success ',
      detail: this.translateService.translate('message.loadingSuccess')
    });
  }

  showSuccessLoadingWithoutData() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success ',
      detail: this.translateService.translate('message.loadingsuccessNoHolidays')
    });
  }

}
