import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'angular-l10n';
import { MyContractTableColumns } from 'src/app/models/mycontract/MyContractTableColumns';
import { MyContractControlService } from 'src/app/services/control-layer/MyContractControlService';
import { Translator } from 'src/app/utils/Translator';

@Component({
  selector: 'app-my-contract',
  templateUrl: './my-contract.component.html',
  styleUrls: ['./my-contract.component.scss']
})
export class MyContractComponent implements OnInit {

  public cols: MyContractTableColumns[] = [];
  private translator: Translator = new Translator(this.translateService);

  constructor(private translateService: TranslationService,
              public myContractControl: MyContractControlService) { }

  ngOnInit(): void {
    this.setup();
  }

  ngAfterViewInit() {
    this.translateService.translationChanged().subscribe(res => {
      this.setup();
    });
  }

  async setup() {
    this.cols = this.translator.getMyContractTableColumns()
    this.myContractControl.load();
  }

}
