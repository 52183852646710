import {UserData} from "../user-data.model";

export class ContractFilter {
  firstName:string;
  lastName:string;
  personalNr:string;
  id: string;
  startDate: string;
  employmentStartDate: string;
  holidayDays: string;
  usedHolidays: string;
  userData: UserData;
  holidayEntitlement: string;

  constructor() {}
}
