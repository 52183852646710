import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserData } from '../models/user-data.model';

@Injectable({
  providedIn: 'root'
})
export class DetailedviewService {

  private isDetailedView = new BehaviorSubject(false);
  globalIsDetailedView = this.isDetailedView.asObservable();

  private mode = new BehaviorSubject(0);
  globalContractListMode = this.mode.asObservable();

  private holidayOwner = new BehaviorSubject<UserData>(null);
  globalContractOwner = this.holidayOwner.asObservable();

  constructor() {
  }

  toggleDetailedView() {
    this.isDetailedView.next(!this.isDetailedView.value);
  }

  resetDetailedView() {
    this.isDetailedView.next(false);
  }

  setMode( mode: number ) {
    this.mode.next(mode);
  }

  setDetailedViewHolidayOwner( userData: UserData ) {
    this.holidayOwner.next(userData);
  }

}
