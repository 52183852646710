export enum RoleType {
  EMPLOYEES = 'employees',
  HR_MANAGERS = 'hr-managers',
  TEAM_LEADS = 'team-leads',
  ME_TIMESHEETS = 'me-timesheets',
  TIMESHEETS = 'timesheets',
  ME_HOLIDAYS = 'me-holidays',
  HOLIDAYS = 'holidays',
  ME_CONTRACTS = 'me-contracts',
  CONTRACTS = 'contracts',
  ME_FILES = 'me-files',
  FILES = 'files'
}
