import { TranslationService } from 'angular-l10n';
import {ContractHistoryMode} from '../models/contracthistory/contract-history-mode';
import {ContractHistoryTableColumn} from '../models/contracthistory/contract-history-table-column';
import { MyContractTableColumns } from '../models/mycontract/MyContractTableColumns';

export class Translator {

  constructor( private translateService: TranslationService ) {
  }

  public getContractListTableColumns( mode: number ): ContractHistoryTableColumn[] {
    const columns = [];

    if ( mode === ContractHistoryMode.MANAGEMENT ) {
      columns.push(new ContractHistoryTableColumn('id', 'text-center', false,
        this.translateService.translate('contractList.number'), 'index_no'));

      columns.push(new ContractHistoryTableColumn('firstName', 'text-center', false,
        this.translateService.translate('contractList.firstName'), 'firstName'));

      columns.push(new ContractHistoryTableColumn('lastName', 'text-center', false,
        this.translateService.translate('contractList.lastName'), 'lastName'));

      columns.push(new ContractHistoryTableColumn('personalNr', 'text-center', false,
        this.translateService.translate('contractList.personalNr'), 'personalNr'));

      columns.push(new ContractHistoryTableColumn('startDate', 'text-center', true,
        this.translateService.translate('contractList.startDate'), 'date'));

      columns.push(new ContractHistoryTableColumn('employmentStartDate', 'text-center', true,
        this.translateService.translate('contractList.employmentStartDate'), 'date'));

      columns.push(new ContractHistoryTableColumn('holidayEntitlement', 'amp-chili text-center', true,
        this.translateService.translate('contractList.holidayDays'), 'number'));

      columns.push(new ContractHistoryTableColumn('actionButtons', 'text-center', false, '', 'actionButtons'));
    }

    return columns;
  }

  public getMyContractTableColumns(): MyContractTableColumns[]{
    const columns = [];

    columns.push(new MyContractTableColumns('personalNr', 'text-center', 
    this.translateService.translate('contractList.personalNr')));

    columns.push(new MyContractTableColumns('startDate', 'text-center', 
    this.translateService.translate('contractList.startDate')));

    columns.push(new MyContractTableColumns('employmentStartDate', 'text-center', 
    this.translateService.translate('contractList.employmentStartDate')));

    columns.push(new MyContractTableColumns('holidayEntitlement', 'text-center', 
    this.translateService.translate('contractList.holidayDays')));

    return columns;
  }
}
