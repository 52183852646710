import { isNullOrUndefined } from 'util';

export default class CssUtils{

 static getCSSClasses(element: HTMLElement): string {
    if (element.hasAttribute('class')) {
      return element.attributes.getNamedItem('class').value;
    }
    return undefined;
  }

  static addCSSClasses( element: HTMLElement, className: string ): void {

    let cssClasses: string = this.getCSSClasses(element);
    if ( isNullOrUndefined(cssClasses) ) {
      let attr: Attr = document.createAttribute('class');
      attr.value = className;
      element.attributes.setNamedItem(attr);
    } else {
      if (cssClasses.indexOf(className) !== -1) {
        return;
      }

      if (cssClasses.length > 0) {
        cssClasses += ' ';
      }
      cssClasses += className;
      element.attributes.getNamedItem('class').value = cssClasses;
    }
  }

  static removeCSSClasses( element: HTMLElement, className: string ): void {

    const cssClasses: string = this.getCSSClasses(element);
    if ( isNullOrUndefined(cssClasses) || cssClasses.length == 0 ) {
      return;
    } else {
      if ( cssClasses.indexOf(className) === -1 ) {
        return;
      }
      const newCssClasses: string = cssClasses.split(' ')
        .filter(p => p !== className)
        .join(' ');
      element.attributes.getNamedItem('class').value = newCssClasses;

    }
  }

  static removeAllCSSClasses(element: HTMLElement): void {
    element.attributes.getNamedItem('class').value = '';
  }

  static removeAllCSSClassesElement(element: Element): void {
    if (element.attributes) {
      if (element.attributes.getNamedItem('class').value.includes('fc-')) {
        element.textContent = '';
        element.attributes.getNamedItem('class').value = '';
      }
    }
  }

  static setDropdownCss() {
    let dropdowns = document.querySelectorAll('.dropdown-menu');
    dropdowns.forEach(element => {
      if ( !!element ) {
        let activeElement = <HTMLElement>element;
        CssUtils.addCSSClasses(activeElement, 'dropdown-clicked');
      }
    });
  }

}
