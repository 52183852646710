import { Table } from 'primeng';
import { TextValidators } from './TextValidators';
import { ContractFilter } from '../models/contracthistory/ContractFilter';
import { Contract } from '../models/contracthistory/contract.model';

export class ContractUtils {

  public textValidators: TextValidators = new TextValidators();

  constructor() {
  }

  sort(contractHistory: Table, direction: string, field: string ) {
      contractHistory.value.sort(( data1, data2 ) => {
      let value1;
      let value2;
      let result = null;

      value1 = data1[field];
      value2 = data2[field];

      result = this.sortingNulls(value1, value2);
      if ( result != null ) { return result; }

      if ( typeof value1 === 'string' && typeof value2 === 'string' ) {
        result = value1.localeCompare(value2);
      }
      else {
        result = ( value1 < value2 ) ? -1 : ( value1 > value2 ) ? 1 : 0;
      }

      return ( this.getSortOrder(direction) * result );
    });

  }

  private sortingNulls( v0, v1 ) {
    if ( v0 == null && v1 != null ) {
      return -1;
    }
    else if ( v0 != null && v1 == null ) {
      return 1;
 }
    else if ( v0 == null && v1 == null ) {
      return 0;
 }
    else { return null; }
  }

  getSortOrder( direction: string ) {
    direction = direction.toLowerCase();
    if ( direction == 'asc' ) {
      return 1;
    } else if ( direction == 'desc' ) {
      return -1;
    } else {
      return 0;
    }
  }

  filterContracts(contracthistory: Contract, contractFilter: ContractFilter, locale ): boolean {

    if ( !this.textValidators.isContains(contracthistory.userData.firstName, contractFilter.firstName) ) {
      return false;
    }

    if ( !this.textValidators.isContains(contracthistory.userData.lastName, contractFilter.lastName) ) {
      return false;
    }

    if ( !this.textValidators.isContains(contracthistory.userData.personalNr, contractFilter.personalNr) ) {
      return false;
    }

    if ( !this.textValidators.containsDate(contracthistory.startDate, contractFilter.startDate, locale) ) {
      return false;
    }

    if ( !this.textValidators.containsDate(contracthistory.employmentStartDate, contractFilter.employmentStartDate, locale) ) {
      return false;
    }

    if ( !this.textValidators.isEqualByValue(contracthistory.holidayEntitlement, contractFilter.holidayEntitlement) ) {
      return false;
    }

    if ( !this.textValidators.isEqualByValue(contracthistory.usedHolidays, contractFilter.usedHolidays) ) {
      return false;
    }

    return true;
  }
}
