export class ContractHistoryTableColumn {
  header: string;
  field: string;
  display = 'table-cell';
  cssClass: string;
  sortable: boolean;
  type: string;

  constructor( field: string, cssClass: string, sortable: boolean, header: string, type?: string ) {
    this.header = header;
    this.field = field;
    this.cssClass = cssClass;
    this.sortable = sortable;
    this.type = type;
  }

  setDisplay( display: string ) {
    this.display = display;
    return this;
  }
}
