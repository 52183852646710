import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopupModalService } from '../../../services/popupmodal.service';
import { PopupModalBody } from '../../../models/popup/popup-modal-body';
import { PopupModalFooter } from '../../../models/popup/popup-modal-footer';
import { PopupModalBodyBuilder } from '../../../models/builders/popup-modal-body-builder';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inputmodal',
  templateUrl: './inputmodal.component.html',
  styleUrls: [ './inputmodal.component.scss' ]
})
export class InputModalComponent implements OnInit, OnDestroy {
  @ViewChild('showInputModal') showInputModal: ElementRef;
  private subscriptionModalStatus: Subscription;
  private subscriptionModalTitle: Subscription;
  private subscriptionModalFooter: Subscription;
  private intervalId;

  modalTitle: string;
  modalBody: PopupModalBody;
  modalFooter: PopupModalFooter;

  constructor( private popupModalService: PopupModalService ) {
    this.modalBody = new PopupModalBody();

    this.subscriptionModalStatus = this.popupModalService.modalStatus.subscribe(status => this.changePopupModalStatus(status));
    this.subscriptionModalTitle = this.popupModalService.modalTitle.subscribe(title => this.setModalTitle(title));
    this.subscriptionModalFooter = this.popupModalService.modalFooter.subscribe(modalFooter => this.popupModalFooter(modalFooter));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptionModalStatus.unsubscribe();
    this.subscriptionModalTitle.unsubscribe();
    this.subscriptionModalFooter.unsubscribe();
  }

  setModalTitle( title: string ) {
    this.modalTitle = title;
  }

  changePopupModalStatus( status: boolean ) {
    this.intervalId = setInterval(() => {
      this.showInputModal.nativeElement.click();
      clearInterval(this.intervalId);
    }, 400);
  }

  popupModalBody( popupModalBody: PopupModalBody ) {
    this.modalBody = popupModalBody;
  }

  sendUserMessage( userMessage: string ) {
    if ( userMessage === '' ) {
      return;
    }

    this.intervalId = setInterval(() => {
      this.popupModalService.setModalBody(new PopupModalBodyBuilder().userMessage(userMessage).build());
      clearInterval(this.intervalId);
    }, 400);

    this.closeModalDialog();
  }

  popupModalFooter( popupModalFooter: PopupModalFooter ) {
    this.modalFooter = popupModalFooter;
  }

  closeModalDialog() {
    this.showInputModal.nativeElement.click();
  }

  setDefaultModal() {
  }
}
