import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  getItem(key: string){
    return localStorage.getItem(key);
  }

  setItem(key: string, value: any){
    localStorage.setItem(key, value);
  }

  getObjectItem(key: string){
    return JSON.parse(localStorage.getItem(key));
  }

  setObjectItem(key: string, value: any){
    localStorage.setItem(key, JSON.stringify(value));
  }

  clear(){
    localStorage.clear();
  }
}
