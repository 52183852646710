import {Injectable} from '@angular/core';
import {ContractApi} from '../api-layer/ContractApi';
import {Contract} from 'src/app/models/contracthistory/contract.model';
import {ContractHistoryLogicService} from '../logic-layer/ContractHistoryLogicService';
import {ContractFilter} from 'src/app/models/contracthistory/ContractFilter';
import {Table} from 'primeng';
import {ContractUtils} from 'src/app/utils/ContractUtils';
import {ContractBuilder} from 'src/app/models/builders/contract-builder.model';
import {MessagingApi} from '../api-layer/MessagingApi';
import {TextValidators} from '../../utils/TextValidators';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ContractHistoryTableColumn} from '../../models/contracthistory/contract-history-table-column';
import {ContractHistoryMode} from '../../models/contracthistory/contract-history-mode';
import {UserData} from '../../models/user-data.model';

@Injectable({
  providedIn: 'root'
})
export class ContractHistoryControlService {

  private utils: ContractUtils = new ContractUtils();
  public contractHistoryData: Contract[] = [];
  public contracts: Contract[] = [];
  public displayedColumns: ContractHistoryTableColumn[];
  public filter: ContractFilter = new ContractFilter();
  public validate: TextValidators = this.utils.textValidators;

  public startDateClone:{[contractid:string]:Date} = {};
  public employmentStartDateClone:{[contractid:string]:Date}  = {};
  public holidayEntitlementClone:{[contractid:string]:number}  = {};

  constructor(private contractApi: ContractApi,
              private logic: ContractHistoryLogicService,
              private messagingApi: MessagingApi) {
    this.filter.userData = new UserData();
  }

  initFilter() {
    if (this.logic.mode === ContractHistoryMode.MANAGEMENT) {
    } else {
      this.filter = new ContractFilter();
    }
  }

  updateDisplayedColumns() {
    this.displayedColumns.forEach(row => {
      if ( row.field === 'firstName' || row.field === 'lastName' || row.field === 'personalNr' ) {
        this.logic.mode === ContractHistoryMode.MANAGEMENT ? row.sortable = true : row.sortable = false;
      }
    });
  }

  sort(contractHistory: Table, direction: string, field: string ) {
    if (isNotNullOrUndefined(contractHistory)) {
      this.utils.sort(contractHistory, direction, field);
    }
  }

  applyFilter() {
    this.contracts = this.contractHistoryData.filter(p => this.utils.filterContracts(p, this.filter, this.logic.locale));
  }

  load() {
    this.logic.loading = true;

    if (this.logic.mode === ContractHistoryMode.MANAGEMENT){
      return this.contractApi.getAllContracts(this.loadContract.bind(this));
    }
  }

  loadContract(data: Contract[]) {
    this.contractHistoryData = [];
    data.forEach(m => {
      if (isNotNullOrUndefined(m.userData) && isNotNullOrUndefined(m.userData.userName)) {
       this.contractHistoryData.push(new ContractBuilder()
        .id(m.id)
        .userData(m.userData)
        .startDate(m.startDate)
        .endDate(m.endDate)
        .employmentStartDate(m.employmentStartDate)
        .holidayEntitlement(m.holidayEntitlement)
        .usedHolidays(m.usedHolidays)
        .build());
    }});
    this.customSort();
    this.contracts = this.contractHistoryData;
    this.logic.loading = false;

    if ( data.length === 0 ) {
      this.messagingApi.showSuccessLoadingWithoutData();
    } else {
      this.messagingApi.showSuccessLoading();
    }
    this.logic.searchDisabled = false;
  }

  onRowEditMode(contract: Contract){
        this.startDateClone[contract.id] = contract.startDate;
        this.employmentStartDateClone[contract.id] = contract.employmentStartDate;
        this.holidayEntitlementClone[contract.id] = contract.holidayEntitlement;
  }

  editContract(contract: Contract){
    if (isNotNullOrUndefined(contract)){
      if (this.logic.mode === ContractHistoryMode.MANAGEMENT){
        contract.holidayEntitlement = parseFloat(contract.holidayEntitlement.toString().replace(',', '.'));
        contract.usedHolidays = parseFloat(contract.usedHolidays.toString().replace(',', '.'));
        return this.contractApi.modifyContract(contract.id, contract);
      }
    }
  }

  cancelEditContract(contract:Contract,index:number){
    this.contracts[index].startDate = this.startDateClone[contract.id];
    this.contracts[index].employmentStartDate = this.employmentStartDateClone[contract.id];
    this.contracts[index].holidayEntitlement = this.holidayEntitlementClone[contract.id];
    delete this.startDateClone[contract.id];
    delete this.employmentStartDateClone[contract.id];
    delete this.holidayEntitlementClone[contract.id];
  }

  private customSort() {
    this.contractHistoryData.sort(this.customSortOrdering.bind(this));
  }

  private customSortOrdering( m0: Contract, m1: Contract ) {
    // First sort by Request Date
    if ( m0.startDate < m1.startDate ) {
      return 1;
    } else if ( m0.startDate > m1.startDate ) {
      return -1;
    }
    return 0;
  }
}
